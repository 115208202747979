let SearchBar = (props) => {
  return (
    <div className="search-container">
      <input
        placeholder={"Playlist URL or URI"}
        onChange={props.onChangeInput}
        type="text"
        id="fname"
        className="search-input-box"
      ></input>
      <span
        onClick={() => {
          props.onClickGoButton();
        }}
        className="goButton"
      >
        GO!
      </span>
    </div>
  );
};

export default SearchBar;
