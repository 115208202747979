function Loading() {
  return (
    <div style={{ "padding-top": "50%" }}>
      <svg
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        data-darkreader-inline-bgimage=""
        data-darkreader-inline-bgcolor=""
      >
        <path
          fill="none"
          stroke="#19d637"
          stroke-width="8"
          stroke-dasharray="42.76482137044271 42.76482137044271"
          d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
          stroke-linecap="round"
          data-darkreader-inline-fill=""
          data-darkreader-inline-stroke=""
        >
          <animate
            attributeName="stroke-dashoffset"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;1"
            values="0;256.58892822265625"
          ></animate>
        </path>
      </svg>
    </div>
  );
}

export default Loading;
