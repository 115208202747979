import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import SearchBar from "../../components/seach-bar";
import logo from "../../img/logo.png";
import loopSong from "../../img/loop_song.png";
import noRepeat from "../../img/no_repeat.png";
import loopPlaylist from "../../img/loop_playlist.png";
import skipBackIcon from "../../img/skip-back.png";
import skipForwardIcon from "../../img/skip-forward.png";

import "../../css/player.css";
import _ from "lodash";
import Loading from "../../components/loading";

const BASE_URL =
  "https://p3to5q2noi.execute-api.us-east-1.amazonaws.com/Prod/search-songs";
const BASE_URL_YOUTUBE = "https://www.youtube.com/watch?v=";

function App() {
  const [searchResults, setSearchResults] = useState([]);
  const [query, setQuery] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);

  const [isNoLoop, setIsNoLoop] = useState(true);
  const [shouldRepeatPlaylist, setShouldRepeatPlaylist] = useState(false);
  const [shouldRepeatSong, setShouldRepeatSong] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  async function handleQuery() {
    if (query.includes("spotify")) {
      setShowLoading(true);
      setCurrentVideo(null);

      try {
        let { data } = await axios.get(BASE_URL + `/?q=${query}`);
        setShowLoading(false); 
        if (data?.length === 0) {
          alert("No song found. :(");
        } else {
          setCurrentVideo(data[0].id);
          setSearchResults(data);
        }
      } catch (error) {
        setShowLoading(false); 
        alert(error?.response?.data?.msg)
      }
    } else {
      alert(
        "Playlist Invalid.\nFollow the examples below:\n\nspotify:playlist:7lvFeM8zOI2h92MHBSSlgP\n\nhttps://open.spotify.com/playlist/7lvFeM8zOI2h92MHBSSlgP?si=R4o4Ey-_QK6fBnVUQwnYOg"
      );
    }
  }

  function handleVideoEnded() {
    let currentVideoIndex = _.findIndex(searchResults, function (s) {
      return s.id == currentVideo;
    });
    if (!shouldRepeatSong) {
      if (currentVideoIndex + 1 >= searchResults.length) {
        if (shouldRepeatPlaylist) setCurrentVideo(searchResults[0].id);
      } else {
        setCurrentVideo(searchResults[currentVideoIndex + 1].id);
      }
    }
  }

  function skipForward() {
    let currentVideoIndex = _.findIndex(searchResults, function (s) {
      return s.id == currentVideo;
    });

    if (currentVideoIndex == 0) {
      setCurrentVideo(searchResults[searchResults.length - 1].id);
      return;
    } else {
      setCurrentVideo(searchResults[currentVideoIndex - 1].id);
    }
  }

  function skipBack() {
    let currentVideoIndex = _.findIndex(searchResults, function (s) {
      return s.id == currentVideo;
    });

    if (currentVideoIndex == 0) {
      setCurrentVideo(searchResults[searchResults.length - 1].id);
      return;
    } else {
      setCurrentVideo(searchResults[currentVideoIndex - 1].id);
    }
  }

  function skipForward() {
    let currentVideoIndex = _.findIndex(searchResults, function (s) {
      return s.id == currentVideo;
    });

    if (currentVideoIndex + 1 >= searchResults.length) {
      setCurrentVideo(searchResults[0].id);
    } else {
      setCurrentVideo(searchResults[currentVideoIndex + 1].id);
    }
  }

  function reapetControolers() {
    return (
      <>
        <img
          src={skipBackIcon}
          alt="Logo"
          height={40}
          width={40}
          onClick={skipBack}
        />
        {isNoLoop && (
          <img
            src={noRepeat}
            alt="Logo"
            height={40}
            width={40}
            onClick={() => {
              setIsNoLoop(false);
              setShouldRepeatPlaylist(true);
            }}
          />
        )}
        {shouldRepeatPlaylist && (
          <img
            src={loopPlaylist}
            alt="Logo"
            height={40}
            width={40}
            onClick={() => {
              setShouldRepeatPlaylist(false);
              setShouldRepeatSong(true);
            }}
          />
        )}
        {shouldRepeatSong && (
          <img
            src={loopSong}
            alt="Logo"
            height={40}
            width={40}
            onClick={() => {
              setShouldRepeatSong(false);
              setIsNoLoop(true);
            }}
          />
        )}
        <img
          src={skipForwardIcon}
          height={40}
          width={40}
          onClick={skipForward}
        />
      </>
    );
  }

  useEffect(() => {}, []);

  return (
    <div
      className={
        currentVideo
          ? "player-container "
          : "player-container center-content full-height"
      }
    >
      <div className="search-bar-player">
        <div className="logs">
          <img src={logo} alt="Logo" height={100} />
        </div>
        <SearchBar
          onChangeInput={(evt) => setQuery(evt.target.value)}
          onClickGoButton={handleQuery}
        />
      </div>

      {(showLoading || searchResults.length > 0) && (
        <div className="player-content">
          {showLoading && <Loading />}
          {currentVideo && (
            <>
              <div className="video-container">
                <ReactPlayer
                  url={`${BASE_URL_YOUTUBE}${currentVideo}`}
                  playing={true}
                  controls={true}
                  onEnded={handleVideoEnded}
                  loop={shouldRepeatSong}
                  width="100%"
                  height="100%"
                />
                <div class="controllers">{reapetControolers()}</div>
              </div>

              <div className="playlist-container">
                {searchResults.map((result) => (
                  <div
                    onClick={(evt) => setCurrentVideo(result.id)}
                    className={result.id == currentVideo && "playlist-item "}
                  >
                    {result.title}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
      <div className="footer">
        <span>
          by weslei.com <span>💚</span>
        </span>
      </div>
    </div>
  );
}

export default App;
